// MessageBubble.js
import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { LogoImage } from './ChatBotLogo';
import { MarkdownContent } from './MarkdownContent';
import { CopyIconButton } from './CopyIcon';

export const MessageBubble = React.memo(({ message, isUser, colors }) => {
  return (
    <Flex alignItems="flex-start">
      {!isUser && <LogoImage />}
      <Box 
        alignSelf={isUser ? 'flex-end' : 'flex-start'}
        bg={isUser ? colors.userMessageBg : colors.botMessageBg}
        color={colors.primaryText}
        py={2}
        px={3}
        borderRadius="lg"
        maxWidth="calc(85% - 50px)"
        boxShadow="sm"
        ml={isUser ? 'auto' : '0'}
        fontSize="sm"
        position="relative"
      >
        <MarkdownContent content={message.content} colors={colors} />
        {!isUser && <CopyIconButton content={message.content} colors={colors} />}
      </Box>
      {isUser && <Box width="40px" />}
    </Flex>
  );
});

export default MessageBubble;