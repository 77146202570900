// utils/statusHelpers.js
export const getStatusColor = (status) => {
    const colors = {
      completed: 'green',
      processing: 'yellow',
      analyzing: 'blue',
      failed: 'red',
      uploading: 'purple'
    };
    return colors[status] || 'gray';
  };