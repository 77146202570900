// components/DocumentGrid.js
import { SimpleGrid } from '@chakra-ui/react';
import { DocumentCard } from './DocumentCard';

export const DocumentGrid = ({ documents, onView, onDelete }) => (
  <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} pb={4}>
    {documents.map((doc) => (
      <DocumentCard
        key={doc.id}
        doc={doc}
        onView={onView}
        onDelete={onDelete}
      />
    ))}
  </SimpleGrid>
);
