// SearchComponent.js
import React, { useState } from 'react';
import { Input, Box } from '@chakra-ui/react';
import { useWebSocket } from '../../contexts/WebSocketContext';

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { sendMessage } = useWebSocket();

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;

    sendMessage({
      type: 'vector_search',
      data: {
        message: searchTerm,
        limit: 5,
        distance: 1
      },
      timestamp: new Date().toISOString(),
    });

    setSearchTerm('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  return (
    <Box maxW="600px" mx="auto" mt={4}>
      <Input
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleKeyPress}
        size="md"
        variant="filled"
      />
    </Box>
  );
};

export default SearchComponent;