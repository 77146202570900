// MainContent.js update
// components/MainContent/MainContent.js
import React, { useState } from 'react';
import { Box, useColorModeValue, IconButton, Icon } from '@chakra-ui/react';
import PDFViewer from '../PDFViewer';
import FileUploadComponent from '../FileUploadComponent';
import { scrollbarStyle } from '../Styles/ScrollbarStyle';
import ChatComponent from '../ChatComponent';
import DocumentManage from '../DocumentManager';
import SearchViewer from '../SearchViewer';
import {QuestionBank} from '../QuestionBank/QuestionBank';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import CameraStream from '../WLIBoro/CameraStream';

const MainContent = ({ activeTab, selectedChatId }) => {
  const [isQuestionBankOpen, setIsQuestionBankOpen] = useState(true);
  const componentBg = useColorModeValue('bg-component', 'bg-component');

  return (
<Box 
  position="absolute"
  top={0}
  left={0}
  right={0}
  bottom={0}
  bg={componentBg}
  display="flex"
  justifyContent="center"
  overflow="hidden"
>
  <Box 
    width="95%" 
    maxWidth={isQuestionBankOpen ? "1400px" : "700px"}
    py={4}
    height="100%"
    display="flex"
    justifyContent="center"
    gap={0}
    transition="all 0.3s ease"
  >
    <Box 
      width="700px"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      {activeTab === 'Research' && <SearchViewer />}
      {activeTab === 'Write' && <DocumentManage />}
      {activeTab === 'Review' && <PDFViewer />}
      {/* {activeTab === 'Crack Detect' && <CameraStream />} */}
      {activeTab === 'Chat' && <ChatComponent selectedChatId={selectedChatId} />}
    </Box>

    {activeTab === 'Chat' && (
      <>
        <Box
          width="24px"
          height="98%"
          bg="bg-component"
          border="1px"
          borderRadius={10}
          borderColor="border-color"
          backgroundColor="gray.700"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={() => setIsQuestionBankOpen(!isQuestionBankOpen)}
          _hover={{
            bg: "whiteAlpha.100"
          }}
          transition="all 0.2s"
          mx={2}
        >
          <Icon
            as={ChevronRightIcon}
            boxSize={6}
            color="text-secondary"
            style={{
              transform: isQuestionBankOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.3s ease',
              transformOrigin: 'center'
            }}
            _hover={{
              color: "text-primary"
            }}
          />
        </Box>

        <Box
          width={isQuestionBankOpen ? "450px" : "0px"}
          display="flex"
          flexDirection="column"
          overflow="hidden"
          transition="all 0.3s ease"
          transform={isQuestionBankOpen ? "scaleX(1)" : "scaleX(0)"}
          transformOrigin="left"
          opacity={isQuestionBankOpen ? 1 : 0}
          visibility={isQuestionBankOpen ? "visible" : "hidden"}
        >
          <QuestionBank />
        </Box>
      </>
    )}
  </Box>
</Box>
  );
};

export default MainContent;