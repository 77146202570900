// hooks/useTextareaLogic.js
import { useRef, useEffect } from 'react';

export const useTextareaLogic = (inputMessage) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const newHeight = Math.min(textarea.scrollHeight, 10 * 20);
      textarea.style.height = `${newHeight}px`;
    }
  }, [inputMessage]);

  return textareaRef;
};