// SearchResults.js
import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Collapse,
  Button,
  Stack,
  useDisclosure,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { useSearch } from '../../contexts/SearchContext';

const ResultCard = ({ result }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Card 
      mb={4}
      bg="bg-component"
      borderColor="border-color"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <CardHeader>
        <Stack spacing={2}>
          <Heading size="md" color="text-primary">
            {result.chapter_heading}
          </Heading>
          <Text color="text-secondary" fontSize="sm">
            Distance: {result.distance.toFixed(4)}
          </Text>
        </Stack>
      </CardHeader>
      <CardBody>
        <Stack spacing={4}>
          <Text color="text-primary">
            {result.content}
          </Text>
          <Button
            variant="secondary"
            size="sm"
            onClick={onToggle}
          >
            {isOpen ? 'Hide Full Chapter' : 'Show Full Chapter'}
          </Button>
          <Collapse in={isOpen} animateOpacity>
            <Box 
              p={4} 
              bg="bg-component" 
              borderRadius="md"
              borderWidth="1px"
              borderColor="border-color"
            >
              <Text color="text-primary">
                {result.full_chapter.full_text}
              </Text>
            </Box>
          </Collapse>
        </Stack>
      </CardBody>
    </Card>
  );
};

const SearchResults = () => {
  const { searchResults = [], isSearching } = useSearch();

  if (isSearching) {
    return (
      <Center py={8}>
        <Spinner size="xl" color="text-primary" />
      </Center>
    );
  }

  if (!Array.isArray(searchResults) || searchResults.length === 0) {
    return (
      <Center py={8}>
        <Text color="text-secondary">No search results available</Text>
      </Center>
    );
  }

  return (
    <Box 
      maxW="800px" 
      mx="auto" 
      mt={4}
      p={4}
    >
      {searchResults.map((result) => (
        <ResultCard key={result.uuid} result={result} />
      ))}
    </Box>
  );
};

export default SearchResults;
