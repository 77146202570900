// LogoImage.js
import React from 'react';
import { Box, Image } from '@chakra-ui/react';
// import logoPath from '../../icons/m_logo.png';
import logoPath from '../../icons/Painless_Logo_small.png';

export const LogoImage = () => (
  <Box
    width="60px"
    height="60px"
    borderRadius="full"
    overflow="hidden"
    mr={2}
    flexShrink={0}
  >
    <Image
      src={logoPath}
      alt="Chatbot Logo"
      objectFit="cover"
      objectPosition="center"
      width="100%"
      height="100%"
      transform="translate(0%, 0%)"
    />
  </Box>
);