// SearchContext.js
import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext(null);

export const SearchProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState([]); // Initialize as empty array
  const [isSearching, setIsSearching] = useState(false);

  const handleSearchResults = (results) => {
    setSearchResults(results || []); // Ensure array even if results is undefined
    console.log('Search Results:', results);
    setIsSearching(false);
  };

  const clearResults = () => {
    setSearchResults([]);
  };

  return (
    <SearchContext.Provider 
      value={{ 
        searchResults, 
        isSearching,
        setIsSearching, 
        handleSearchResults,
        clearResults 
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within SearchProvider');
  }
  return context;
};