import React from 'react';
import { Textarea } from '@chakra-ui/react';
import { useTextareaLogic } from '../../hooks/TextInputArea/useTextareaLogic';

export const ChatTextarea = ({ inputMessage, setInputMessage, handleKeyPress, colors,pl,pr,onFocus, onBlur, buttonWidth }) => {
  const textareaRef = useTextareaLogic(inputMessage);
  return (
    <Textarea 
      ref={textareaRef}
      value={inputMessage} 
      onChange={(e) => setInputMessage(e.target.value)} 
      placeholder=" "
      onKeyPress={handleKeyPress}
      onFocus={onFocus}
      onBlur={onBlur}
      bg={colors.inputBg}
      color={colors.primaryText}
      borderColor={colors.inputBorder}
      className="custom-scrollbar"
      css={{
        '&::-webkit-scrollbar': {
          width: '7px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '3px',
          marginBlock: '15px',  // Add vertical margin to create space at top and bottom
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(255, 255, 255, 0.3)',
          borderRadius: '3px',
          //height: '90%',          // Set a fixed height instead of percentage
          maxHeight: '50%',        // Limit maximum height
          margin: 'auto',          // Center the thumb
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.5)',
          }
        },
      }}
      sx={{
        '&:focus': {
          outline: 'none',
          boxShadow: 'none',
          //borderColor: colors.inputFocus,
          border: `1px solid ${colors.inputFocus}`,
        },
        // '&:hover': {
        //   borderColor: colors.inputBorder,  // Maintain consistent border on hover
        // },
        borderRadius: '20px',
        paddingLeft: pl,
        paddingRight: pr,
        paddingTop: '15px',
        paddingBottom: '1px',
        boxSizing: 'border-box',
        width: '100%',
        position: 'relative',
        lineHeight: '24px',
        minHeight: '60px',
        display: 'flex',
        alignItems: 'center',
        transition: 'border-color 0.2s ease-in-out',
        // Add padding to prevent scrollbar from touching border radius
        '&::-webkit-scrollbar-track-piece': {
          background: 'transparent',
          marginBlock: '0px',
        },
        // Create space around the scrollbar
        '&::-webkit-scrollbar-corner': {
          background: 'transparent'
        },
      }}
      minH="60px"
      maxH="200px"
      overflow="auto"
      resize="none"
      rows={1}        
    />
  );
};