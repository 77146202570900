
// //GradientOverlay.js
import { Box, useColorModeValue } from '@chakra-ui/react';

export function GradientOverlay({ isAtBottom, isInputFocused }) {
  const bgColor = useColorModeValue('bg-component', 'bg-component');

  return (
    <Box
      position="absolute"
      bottom="0"
      left="0"
      right="0"
      height="120px"
      pointerEvents="none"
      zIndex={2}
      opacity={isAtBottom || isInputFocused ? 0 : 1}
      transition="opacity 0.3s ease-in-out" // Smooth transition
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: '-20px',
        right: '-20px',
        height: '100%',
        backgroundImage: `linear-gradient(to bottom, 
          rgba(var(--chakra-colors-${bgColor}-rgb), 0) 0%,
          rgba(var(--chakra-colors-${bgColor}-rgb), 0.8) 60%,
          rgba(var(--chakra-colors-${bgColor}-rgb), 1) 100%
        )`,
        filter: 'blur(10px)',
      }}
    />
  );
}