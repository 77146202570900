// ChatComponent.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, useToken, useColorModeValue } from '@chakra-ui/react';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useChat } from '../contexts/ChatContext';
import { useScrollLogic } from '../hooks/useScrollLogic';
import { ChatContainer } from './ChatOutputArea/ChatContainer';
import { InputArea } from './ChatInputArea/InputArea';
import { GradientOverlay } from './GradientOverlay';
import { v4 as uuidv4 } from 'uuid';

function ChatComponent({ selectedChatId }) {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const chatContainerRef = useRef(null);
  const { isConnected, sendMessage } = useWebSocket();
  const {
    messages,
    currentResponse,
    addUserMessage,
    ensureActiveChat,
    loadChat,
    reloadCurrentChat,
    constructPrompt,
  } = useChat();

  const { isAtBottom, scrollToBottom, handleScroll } = useScrollLogic(chatContainerRef);
  const [componentBg] = useToken('colors', ['bg-component']);
  const bgColor = useColorModeValue('bg-component-light', 'bg-component-dark');
  const borderColor = useColorModeValue('border-color-light', 'border-color-dark');


  // Load chat when selectedChatId changes
  useEffect(() => {
    if (selectedChatId) {
      loadChat(selectedChatId);
    }
  }, [selectedChatId, loadChat]);

  // Initial chat reload
  useEffect(() => {
    reloadCurrentChat();
  }, [reloadCurrentChat]);

  const handleSendMessage = useCallback(async () => {
    if (isConnected && inputMessage.trim()) {
      const chatId = await ensureActiveChat();
      if (chatId) {
        const messageId = uuidv4();
        // Safe call with type check
      const taskPrompt = typeof constructPrompt === 'function' 
      ? constructPrompt()
      : null;
      
      const finalMessage = taskPrompt 
      ? `${taskPrompt}\n\nUser Input: ${inputMessage}`
      : inputMessage;
      
      console.log('finaleMessage', finalMessage)
        sendMessage({
          type: 'chat',
          data: {
            message: finalMessage,
            chat_id: chatId,
            messageId: messageId,
          },
          timestamp: new Date().toISOString(),
        });
        addUserMessage(inputMessage, messageId);
        setInputMessage('');
        scrollToBottom();
      }
    }
  }, [isConnected, inputMessage, ensureActiveChat, addUserMessage, sendMessage, scrollToBottom]);

  return (
    <Box 
      height="100%" 
      width="100%" 
      display="flex" 
      flexDirection="column" 
      bg={componentBg} 
      position="relative" 
      overflow="hidden"
    >
      <ChatContainer 
        chatContainerRef={chatContainerRef}
        handleScroll={handleScroll}
        messages={messages}
        currentResponse={currentResponse}
      />
      <GradientOverlay 
        isAtBottom={isAtBottom} 
        isInputFocused={isInputFocused}
      />
      <Box position="relative">
        <InputArea 
          inputMessage={inputMessage} 
          setInputMessage={setInputMessage} 
          sendMessage={handleSendMessage}
          onFocusChange={setIsInputFocused}
          colors={{
            componentBg: bgColor,
            border: borderColor,
          }}
        />
      </Box>
    </Box>
  );
}

export default ChatComponent;
