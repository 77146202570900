// WebSocketContext.js
import React, { createContext, useContext, useCallback, useEffect, useState, useRef } from 'react';
import { useAuth } from './AuthContext';
import { CONFIG } from '../config';
import { v4 as uuidv4 } from 'uuid';

const WebSocketCloseCode = {
    INACTIVITY: 4000,
    TOKEN_EXPIRED: 4001,
    INVALID_TOKEN: 4002,
    NO_TOKEN: 4003
  };

const WebSocketContext = createContext(null);

export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};

const getMachineId = () => {
    let machineId = localStorage.getItem('machine_id');
    if (!machineId) {
        machineId = uuidv4();
        localStorage.setItem('machine_id', machineId);
    }
    return machineId;
};

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [showReconnectNotification, setShowReconnectNotification] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const { accessToken, isInitialized, refreshAccessToken } = useAuth();
    const url = CONFIG.WEBSOCKET_URL + CONFIG.LLM_WEBSOCKET_URL;
    const machineId = getMachineId();
    const reconnectTimeoutRef = useRef(null);
    const connectRef = useRef(null);

    // Initialize handleTokenRefresh first
    const handleTokenRefreshRef = useRef(async () => {
        try {
            await refreshAccessToken();
            connectRef.current?.();
        } catch (error) {
            console.error('Failed to refresh token:', error);
        }
    });

    connectRef.current = () => {
        if (!accessToken || !isInitialized) return;

        if (socket) {
            socket.close();
        }

        const newSocket = new WebSocket(
            `${url}?token=${encodeURIComponent(accessToken)}&machine_id=${machineId}`
        );

        // Add binary type support
        newSocket.binaryType = 'blob';
    
        newSocket.onopen = () => {
            console.log('WebSocket Connected');
            setIsConnected(true);
        };

        newSocket.onclose = (event) => {
            console.log('WebSocket Disconnected', event.code);
            setIsConnected(false);
            
            if (event.code === WebSocketCloseCode.INACTIVITY) {
                setShowReconnectNotification(true);
                return;
            }
            // Don't auto-reconnect on inactivity timeout
            if (event.code === WebSocketCloseCode.INACTIVITY) {
                console.log('Connection closed due to inactivity');
                return;
            }
            
            if (event.code === 4001) {
                handleTokenRefreshRef.current();
            } else if (![4002, 4003].includes(event.code)) {
                reconnectTimeoutRef.current = setTimeout(connectRef.current, 3000);
            }
        };

        newSocket.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        setSocket(newSocket);
    };

    const connect = useCallback(() => {
        connectRef.current();
    }, []);

    useEffect(() => {
        if (isInitialized) {
            connect();
        }
        
        return () => {
            if (socket) {
                socket.close();
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
        };
    }, [connect, isInitialized]);
    
    // Add new image sending method
    const sendImage = useCallback((blob) => {
        if (socket && isConnected) {
            // First send metadata message
            socket.send(JSON.stringify({
                type: 'camera_stream_start',
                data: {
                    timestamp: Date.now(),
                    contentType: blob.type,
                    size: blob.size
                }
            }));
            
            // Then send binary data
            socket.send(blob);
        }
    }, [socket, isConnected]);
    const sendMessage = useCallback((message) => {
        if (socket && isConnected) {
            socket.send(JSON.stringify(message));
        } else {
            console.error('Cannot send message, socket is not connected');
        }
    }, [socket, isConnected]);

    return (
        <WebSocketContext.Provider value={{ 
        socket, 
        isConnected, 
        sendMessage, 
        sendImage,
        isConnected, 
        showReconnectNotification,
        }}>
            {children}
        </WebSocketContext.Provider>
    );
};