// theme.js
import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const fonts = {
  heading: `'Roboto', sans-serif`,
  body: `'Roboto', sans-serif`,
};

const colors = {
  brand: {
    50: '#f0f0f0',
    100: '#d9d9d9',
    200: '#bfbfbf',
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#0d0d0d',
  },
};

const semanticTokens = {
  colors: {
    'bg-page': {
      default: 'gray.50',
      _dark: 'gray.900',
    },
    'bg-component': {
      default: 'white',
      _dark: 'gray.800',
    },
    'bg-component-rgb': {
      default: '255, 255, 255',
      _dark: '26, 32, 44',
    },
    'text-primary': {
      default: 'gray.800',
      _dark: 'gray.200',
    },
    'text-secondary': {
      default: 'gray.600',
      _dark: 'gray.400',
    },
    'border-color': {
      default: 'gray.200',
      _dark: 'gray.600',
    },
    'button-primary': {
      default: 'gray.600',
      _dark: 'gray.900',
    },
    'button-primary-hover': {
      default: 'gray.800',
      _dark: 'gray.600',
    },
    'button-text': {
      default: 'white',
      _dark: 'grey.100',
    },
    'button-border': {
      default: 'grey.100',
      _dark: 'grey.100',
    },
    'message-user': {
      default: 'gray.100',
      _dark: 'gray.600',
    },
    'message-bot': {
      default: 'gray.100',
      _dark: 'gray.700',
    },
    'scrollbar-thumb': {
      default: 'gray.300',
      _dark: 'gray.600',
    },
    'input-bg': {
      default: 'white',
      _dark: 'gray.700',
    },
    'input-border': {
      default: 'gray.300',
      _dark: 'gray.600',
    },
    'input-focus': {
      default: 'gray.500',
      _dark: 'gray.400',
    },
    'icon': {
      default: 'gray.600',
      _dark: 'gray.400',
    },
    'icon-active': {
      default: 'gray.800',
      _dark: 'gray.200',
    },
  },
};

const components = {
  Button: {
    baseStyle: {
      fontWeight: '500',
      borderRadius: '20px',
      transition: 'background-color .218s, border-color .218s, box-shadow .218s',
      letterSpacing: '0.25px',
    },
    sizes: {
      md: {
        height: '40px',
        padding: '0 12px',
        fontSize: '14px',
      },
    },
    variants: {
      primary: {
        bg: 'button-primary',
        color: 'button-text',
        borderColor: 'button-border',
        borderWidth: '1px',
        _hover: {
          bg: 'button-primary-hover',
          boxShadow: '0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)',
        },
        _active: {
          bg: 'gray.700',
        },
        _disabled: {
          opacity: 0.38,
          cursor: 'default',
          bg: 'gray.600',
          borderColor: 'gray.500',
        },
      },
      secondary: {
        bg: 'transparent',
        color: 'text-primary',
        borderColor: 'border-color',
        borderWidth: '1px',
        _hover: {
          bg: 'gray.100',
          _dark: {
            bg: 'gray.900',
          },
          boxShadow: '0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)',
        },
        _active: {
          bg: 'gray.200',
          _dark: {
            bg: 'gray.600',
          },
        },
        _disabled: {
          opacity: 0.38,
          cursor: 'default',
          bg: 'transparent',
          borderColor: 'gray.300',
          _dark: {
            borderColor: 'gray.600',
          },
        },
      },
      tertiary:{
        bg: 'gray.800',
        color: 'text-primary',
        borderColor: 'border-color',
        borderWidth: '1px',
        _hover: {
          bg: 'gray.100',
          _dark: {
            bg: 'gray.900',
          },
          boxShadow: '0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)',
        },
        _active: {
          bg: 'gray.200',
          _dark: {
            bg: 'gray.600',
          },
        },
        _disabled: {
          opacity: 0.38,
          cursor: 'default',
          bg: 'transparent',
          borderColor: 'gray.300',
          _dark: {
            borderColor: 'gray.600',
          },
        },
      },
    },
    defaultProps: {
      size: 'md',
      variant: 'primary',
    },
  },
  // Button: {
  //   baseStyle: {
  //     fontWeight: 'bold',
  //   },
  //   variants: {
  //     primary: {
  //       bg: 'button-primary',
  //       color: 'button-text',
  //       borderColor: 'button-border',
  //       borderWidth: { base: '0.5px', md: '0.25px' },
  //       _hover: {
  //         bg: 'button-primary-hover',
  //       },
  //     },
  //   },
  // },
  Input: {
    variants: {
      outline: {
        field: {
          bg: 'input-bg',
          borderColor: 'input-border',
          _focus: {
            borderColor: 'input-focus',
            boxShadow: '0 0 0 1px var(--chakra-colors-input-focus)',
          },
        },
      },
    },
  },
  Textarea: {
    variants: {
      chatInput: {
        field: {
          borderRadius: '20px',
          paddingTop: '18px',
          paddingBottom: '18px',
          lineHeight: '24px',
          minHeight: '60px',
          maxHeight: '200px',
          resize: 'none',
        },
      },
    },
  },
  InputArea: {
    baseStyle: {
      textarea: {
        borderRadius: 'md',
        _focus: {
          boxShadow: 'none',
          borderWidth: '1px',
        },
      },
    },
    variants: {
      outline: {
        textarea: {
          border: '1px solid',
          borderColor: 'border-color',
        },
      },
    },
    defaultProps: {
      variant: 'outline',
    },
  },
};

const styles = {
  global: (props) => ({
    body: {
      bg: 'bg-page',
      color: 'text-primary',
    },
  }),
};

const theme = extendTheme({
  config,
  fonts,
  colors,
  semanticTokens,
  components,
  styles,
});

export default theme;