// components/ChatHistoryList.js
import React from 'react';
import { 
  Box, 
  VStack, 
  Text, 
  Button, 
  Spinner, 
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  SlideFade, // Add this
  Fade, // Add this
} from '@chakra-ui/react';
import { DeleteIcon, TimeIcon } from '@chakra-ui/icons';
import { useChat } from '../contexts/ChatContext';
import { useToast } from '@chakra-ui/react';

const ChatHistoryItem = ({ chat, onSelect, onDelete }) => {
  const hoverBg = useColorModeValue('gray.100', 'whiteAlpha.200');
  const gradientColor = useColorModeValue(
    'rgba(255, 255, 255, 1)',
    'rgba(26, 32, 44, 1)'
  );

  const getPreviewText = (history) => {
    if (!history || !Array.isArray(history)) return '';
    const firstMessage = history.find(msg => msg.role === 'user' || msg.role === 'assistant');
    if (!firstMessage) return '';
    return firstMessage.content;
  };

  return (
    <SlideFade in={true} offsetY="20px">
      <Box
        position="relative"
        width="100%"
        cursor="pointer"
        onClick={() => onSelect(chat.id)}
        _hover={{ bg: hoverBg }}
        borderRadius="md"
        pl={6}
        pr={3}
        py={1}
      >
        <Box
          position="relative"
          pr={0}
        >
          <Box
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            width="90px"
            background={`linear-gradient(to right, transparent, ${gradientColor} 95%)`}
            pointerEvents="none"
            zIndex="1"
          />
          <Text 
            fontSize="sm" 
            color="text-secondary"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            position="relative"
          >
            {getPreviewText(chat.history)}
          </Text>
        </Box>
        <IconButton
          icon={<DeleteIcon />}
          variant="ghost"
          size="sm"
          position="absolute"
          right={1}
          top="50%"
          transform="translateY(-50%)"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(chat.id, e);
          }}
          aria-label="Delete chat"
          _hover={{ color: 'red.500' }}
          zIndex="2"
        />
      </Box>
    </SlideFade>
  );
};

const ChatHistoryList = ({ onSelectChat }) => {
  const { chatHistories, deleteChatHistory } = useChat();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleDelete = async (chatId, e) => {
    try {
      await deleteChatHistory(chatId);
      toast({
        title: "Chat deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting chat",
        description: "Please try again later",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!chatHistories) {
    return <Spinner />;
  }

  if (chatHistories.length === 0) {
    return <Text>No chat history available.</Text>;
  }

  const recentChats = chatHistories.slice(0, 5);

  return (
    <Fade in={true}>
      <VStack spacing={1} align="stretch">
        <Box px={2}>
          <Button
            leftIcon={<TimeIcon />}
            onClick={onOpen}
            size="sm"
            variant="outline"
            width="100%"
            mb={2}
          >
            View Full History
          </Button>
        </Box>

        {recentChats.map((chat, index) => (
          <ChatHistoryItem
            key={chat.id}
            chat={chat}
            onSelect={onSelectChat}
            onDelete={handleDelete}
            style={{ 
              animationDelay: `${index * 100}ms` 
            }}
          />
        ))}

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Chat History</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Text>Full chat history will be displayed here</Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
    </Fade>
  );
};

export default ChatHistoryList;