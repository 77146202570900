// components/ReconnectAlert.jsx
import React from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Text,
  SlideFade,
  useColorModeValue,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { useWebSocket } from '../contexts/WebSocketContext';

export const ReconnectAlert = () => {
  const { showReconnectNotification } = useWebSocket();
  const bgColor = useColorModeValue('bg-component', 'gray.800');
  const borderColor = useColorModeValue('border-color', 'gray.600');

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <SlideFade in={showReconnectNotification} offsetY="-20px">
      <Box
        position="fixed"
        top={4}
        right={4}
        zIndex="toast"
        maxW="sm"
        w="full"
      >
        <Alert 
          status="error"
          variant="subtle"
          borderRadius="20px"
          borderWidth="1px"
          borderColor={borderColor}
          bg={bgColor}
          boxShadow="lg"
          p={4}
        >
          <HStack spacing={4} width="full" justify="space-between" align="center">
            <HStack spacing={3}>
              <AlertIcon />
              <Box>
                <Text color="text-primary" fontWeight="500">
                  Connection Terminated
                </Text>
                <Text color="text-secondary" fontSize="sm">
                  Session expired due to inactivity
                </Text>
              </Box>
            </HStack>
            <Button
              variant="primary"
              size="md"
              leftIcon={<RepeatIcon />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
          </HStack>
        </Alert>
      </Box>
    </SlideFade>
  );
};