// components/DocumentCard.js
import { Card, CardBody, VStack, HStack, Text, Badge, IconButton } from '@chakra-ui/react';
import { ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { formatDate } from '../utils/formatters';
import { getStatusColor } from '../utils/statusHelpers';

export const DocumentCard = ({ doc, onView, onDelete }) => (
  <Card key={doc.id} variant="outline">
    <CardBody>
      <VStack align="stretch" spacing={2}>
        <Text fontWeight="bold" noOfLines={1}>{doc.file_name}</Text>
        <HStack justify="space-between">
          <Badge colorScheme={getStatusColor(doc.status)}>
            {doc.status}
          </Badge>
          <Text fontSize="sm">
            {formatDate(doc.created_at)}
          </Text>
        </HStack>
        <Text fontSize="sm">Pages: {doc.page_count}</Text>
        <HStack justify="flex-end" pt={2}>
          <IconButton
            icon={<ViewIcon />}
            size="sm"
            onClick={() => onView(doc)}
            aria-label="View document details"
          />
          <IconButton
            icon={<DeleteIcon />}
            size="sm"
            colorScheme="red"
            onClick={() => onDelete(doc.id)}
            aria-label="Delete document"
          />
        </HStack>
      </VStack>
    </CardBody>
  </Card>
);