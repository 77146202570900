// components/ChatInputArea/PlaceholderText.js
import React from 'react';
import { Text, Box } from '@chakra-ui/react';

export const PlaceholderText = ({ colors }) => (
  <Box position="absolute" right="120px" bottom="50%" transform="translateY(50%)" pointerEvents="none">
    <Text color={colors.primaryText} opacity={0.7}>
      Tell me what you need
    </Text>
  </Box>
);