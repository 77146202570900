// hooks/useDocuments.js
import { useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import api from '../../../services/api';
import { CONFIG, getApiUrl } from '../../../config';

export const useDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const toast = useToast();

  const fetchDocuments = useCallback(async () => {
    try {
      setIsRefreshing(true);
      console.log('API URL:', getApiUrl(CONFIG.PDF_UPLOAD_ENDPOINT), CONFIG); 
      const response = await api.get(getApiUrl(CONFIG.PDF_UPLOAD_ENDPOINT));
      setDocuments(response.data);
      toast({
        title: 'Documents updated',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error fetching documents',
        description: error.response?.data?.error || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsRefreshing(false);
    }
  }, [toast]);

  return {
    documents,
    setDocuments,
    isLoading,
    isRefreshing,
    fetchDocuments
  };
};
