import axios from 'axios';
import { CONFIG } from '../config.js';
//import {refreshAccessToken } from '../contexts/AuthContext.js';
//import { useAuth, AuthProvider } from '../contexts/AuthContext.js';
// import https from 'https';
// import { cert } from '../cert.js';

// Create axios instance with environment-specific configuration
let axiosConfig = {
  baseURL: CONFIG.API_BASE_URL,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};

const getCsrfToken = async () => {
  try {
    const response = await api.get('/csrf/');
    const csrfToken = response.data.csrfToken;
    // Manually set the token in axios defaults
    api.defaults.headers.common['X-CSRFToken'] = csrfToken;
    return csrfToken;
  } catch (error) {
    console.error('Failed to get CSRF token:', error);
    throw error;
  }
};

export const initializeCSRF = async () => {
  try {
    await getCsrfToken();
  } catch (error) {
    console.error('Failed to initialize CSRF:', error);
  }
};

// Only add HTTPS configuration in development
if (process.env.NODE_ENV === 'development') {
  console.log('Development mode detected, configuring HTTPS');
  import('https').then(https => {
    import('../cert.js').then(({ cert }) => {
      axiosConfig.httpsAgent = new https.Agent({
        ca: cert
      });
    });
  });
} else {
  console.log('Production mode detected, skipping HTTPS configuration');
}

const api = axios.create(axiosConfig);

let isRefreshing = false;
let failedQueue = [];
let refreshTokenFunction = null;

export const setRefreshTokenFunction = (fn) => {
  refreshTokenFunction = fn;
};

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    const csrfToken = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    
    console.log('Cookies:', document.cookie);
    console.log('CSRF Token:', csrfToken);
    
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }
    
    console.log('Request headers:', config.headers);
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          localStorage.setItem('accessToken', token); // Update localStorage
          return api(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newToken = await refreshTokenFunction();
        localStorage.setItem('accessToken', newToken); // Update localStorage
        processQueue(null, newToken);
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default api;