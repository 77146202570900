// QuestionBank.js
import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Stack,
  Card,
  CardBody,
  Text,
  IconButton,
  HStack,
  VStack,
  Icon,
  Tooltip,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { scrollbarStyle } from '../Styles/ScrollbarStyle';
import { useChat } from '../../contexts/ChatContext';

const QuestionCard = ({ question, onDelete, onMoveUp, onMoveDown, isFirst, isLast, number, onTypeChange }) => {
    const [isFavorite, setIsFavorite] = useState(true);
  
    return (
        <Card mb={2} bg="bg-component" borderColor="border-color" borderWidth="1px" borderRadius="lg">
        <CardBody p={0}>
          <VStack align="stretch" spacing={2}>
            <HStack 
              justify="space-between" 
              p={2}
              bg="whiteAlpha.100"
              borderTopRadius="lg"
              pr={4}
            >
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="ghost"
                  color="text-primary"
                  fontWeight="700"
                  fontSize="lg"
                >
                  {number}. {question.type}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => onTypeChange(question.id, 'Question')}>Question</MenuItem>
                  <MenuItem onClick={() => onTypeChange(question.id, 'Task')}>Task</MenuItem>
                </MenuList>
              </Menu>
              
              <Tooltip 
                label="Disable Question" 
                placement="top" 
                hasArrow
                bg="gray.700"
                color="white"
              >
                <IconButton
                  icon={<Icon as={isFavorite ? AiFillHeart : AiOutlineHeart} boxSize={4}/>}
                  variant="ghost"
                  size="sm"
                  color={isFavorite ? "red.400" : "gray.400"}
                  onClick={() => setIsFavorite(!isFavorite)}
                  aria-label="Toggle favorite"
                />
              </Tooltip>
            </HStack>
              
            <Box px={4} pb={4}>
              <HStack justify="space-between">
                <Text 
                  color="text-primary"
                  fontSize="md"
                >
                  {question.text}
                </Text>
                <HStack spacing={1}>
                  <VStack spacing={1} align="center">
                    {!isFirst && (
                      <IconButton
                        icon={<ChevronUpIcon boxSize={6} />}
                        variant="ghost"
                        size="xs"
                        onClick={() => onMoveUp(question.id)}
                        aria-label="Move up"
                      />
                    )}
                    <IconButton
                      icon={<DeleteIcon boxSize={4} />}
                      variant="ghost"
                      size="sm"
                      onClick={() => onDelete(question.id)}
                      aria-label="Delete question"
                    />
                    {!isLast && (
                      <IconButton
                        icon={<ChevronDownIcon boxSize={6} />}
                        variant="ghost"
                        size="xs"
                        onClick={() => onMoveDown(question.id)}
                        aria-label="Move down"
                      />
                    )}
                  </VStack>
                </HStack>
              </HStack>
            </Box>
          </VStack>
        </CardBody>
      </Card>
    );
  };

export const QuestionBank = () => {
//   const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newItemType, setNewItemType] = useState('Question');
  const containerRef = useRef(null);
  const bgColor = useColorModeValue('bg-component', 'bg-component');
  const { 
    questions, 
    addQuestion, 
    deleteQuestion, 
    moveQuestion, 
    updateQuestionType 
  } = useChat();

  // Add loading state
  const [isLoading, setIsLoading] = useState(true);

  // Check if context is ready
  useEffect(() => {
    if (questions !== undefined) {
      setIsLoading(false);
    }
  }, [questions]);
  
  const handleAddQuestion = () => {
    if (newQuestion.trim()) {
      addQuestion(newQuestion, newItemType);
      setNewQuestion('');
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }
  };

    // First add this helper function at the top of your component
    const autoResize = (e) => {
        const textarea = e.target;
        const lineHeight = 25; // Approximate line height in pixels
        const maxHeight = lineHeight * 10; // Height for 10 lines
        
        textarea.style.height = 'auto';
        if (textarea.scrollHeight <= maxHeight) {
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.overflowY = 'hidden';
        } else {
            textarea.style.height = `${maxHeight}px`;
            textarea.style.overflowY = 'auto';
        }
    };

    const resetTextareaHeight = (textarea) => {
        textarea.style.height = '2.5rem'; // Match minH value
        textarea.style.overflowY = 'hidden';
    };
  return (
    <Box 
      height="100%" 
      width="100%" 
      display="flex" 
      flexDirection="column" 
      bg={bgColor} 
      position="relative"
      overflow="hidden"
      pb={4} // Add bottom padding to create space
    >
      {isLoading ? (
      <Box flex="1" p={4}>
        Loading questions...
      </Box>
    ) : (
      <Box 
        flex="1" 
        overflowY="auto"
        ref={containerRef}
        className="custom-scrollbar"
        css={scrollbarStyle}
        p={4}
        mb={0}
      >
        <Stack spacing={2}>
          {questions.map((q, index) => (
            <QuestionCard 
              key={q.id} 
              question={q} 
              onDelete={deleteQuestion}
              onMoveUp={() => moveQuestion(q.id, -1)}
              onMoveDown={() => moveQuestion(q.id, 1)}
              isFirst={index === 0}
              isLast={index === questions.length - 1}
              number={index + 1}
              onTypeChange={updateQuestionType}
            />
          ))}
        </Stack>
      </Box>
    )}
      <Box 
  position="sticky"
  bottom={0}
  mx={4}
  borderRadius="2xl"
  bg="gray.700"
  borderWidth="1px"
  borderColor="whiteAlpha.200"
  boxShadow="0 8px 32px rgba(0, 0, 0, 0.1)"
  width="auto"
  zIndex={1}
>
        <VStack spacing={4} pl={4} pr={4} pb={2} pt= {2}>
        <Textarea
    value={newQuestion}
    onChange={(e) => {
        setNewQuestion(e.target.value);
        autoResize(e);
    }}
    onKeyPress={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleAddQuestion();
            resetTextareaHeight(e.target);
        }
    }}
            placeholder={`Type your ${newItemType.toLowerCase()}...`}
            bg="gray.700"
            border="none"
            _focus={{
                boxShadow: "none",
                borderColor: "transparent"
            }}
            _hover={{
                borderColor: "transparent"
            }}
            fontSize="1rem"
            color="text-primary"
            resize="none"
            rows={1}
            minH="2.5rem"
            maxH="250px"
            height="auto"
            position="relative"
            zIndex={2}
            css={{
                ...scrollbarStyle,
                lineHeight: "1.5rem",
                transition: "height 0.1s ease-out"
            }}
            className="custom-scrollbar"
        />

    <HStack 
      width="100%" 
      justify="space-between" 
      align="center"
      flexWrap="wrap"
      gap={3}
    >
      <HStack spacing={3} flex="1 1 auto">
        <Text 
          fontSize="0.875rem" 
          color={newItemType === 'Task' ? 'text-primary' : 'text-secondary'}
        >
          Task
        </Text>
        <Switch
          size="md"
          isChecked={newItemType === 'Question'}
          onChange={() => setNewItemType(prev => 
            prev === 'Question' ? 'Task' : 'Question'
          )}
        />
        <Text 
          fontSize="0.875rem" 
          color={newItemType === 'Question' ? 'text-primary' : 'text-secondary'}
        >
          Question
        </Text>
      </HStack>

      <Button
        variant="primary"
        onClick={handleAddQuestion}
        isDisabled={!newQuestion.trim()}
        width="120px"
        height="40px"
        borderRadius="xl"
        bg="button-primary"
        _hover={{
          bg: "button-primary-hover",
          transform: "translateY(-1px)"
        }}
        transition="all 0.2s"
      >
        Add
      </Button>
    </HStack>
  </VStack>
</Box>
    </Box>
  );
};