// hooks/useScrollLogic.js
import { useState, useCallback, useEffect } from 'react';

export function useScrollLogic(chatContainerRef) {
  const [isAtBottom, setIsAtBottom] = useState(true);

  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current && isAtBottom) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [isAtBottom, chatContainerRef]);

  const handleScroll = useCallback(() => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      setIsAtBottom(scrollHeight - scrollTop - clientHeight < 1);
    }
  }, [chatContainerRef]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  return { isAtBottom, scrollToBottom, handleScroll };
}