// components/SettingsButton.js
import React from 'react';
import { IconButton, Button, useDisclosure } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import CustomModal from './CustomModal';
import Settings from './Settings';

const SettingsButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        icon={<SettingsIcon color="icon"  boxSize={6}/>}
        onClick={onOpen}
        variant="unstyled"
        aria-label="Settings"
      />
      <CustomModal isOpen={isOpen} onClose={onClose} title="Settings">
        <Settings />
      </CustomModal>
    </>
  );
};
//   return (
//     <>
//       <Button
//         leftIcon={<SettingsIcon color="icon" />}
//         onClick={onOpen}
//         variant="secondary"
//       >
//         Settings
//       </Button>
//       <CustomModal isOpen={isOpen} onClose={onClose} title="Settings">
//         <Settings />
//       </CustomModal>
//     </>
//   );
// };

export default SettingsButton;