// SearchViewer.js
import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import SearchComponent from "./VectorSearch/SearchComponent";
import SearchResults from './VectorSearch/SearchResult';
import { scrollbarStyle } from './Styles/ScrollbarStyle';

const SearchViewer = () => {
  return (
    <Box 
      height="100%"
      width="100%"
      overflowY="auto"
      className="custom-scrollbar"
      css={scrollbarStyle}
    >
      <VStack spacing={8} w="100%" p={4}>
        <SearchComponent />
        <SearchResults />
      </VStack>
    </Box>
  );
};

export default SearchViewer;
// // SearchViewer.js
// import React from 'react';
// import SearchComponent from "./VectorSearch/SearchComponent";
// import SearchResults from './VectorSearch/SearchResult';
// import { VStack } from '@chakra-ui/react';

// const SearchViewer = () => {
//   return (
//     <VStack spacing={8} w="100%" p={4}>
//       <SearchComponent />
//       <SearchResults />
//     </VStack>
//   );
// };

// export default SearchViewer;