// components/TestButton.js
import React, { useState } from 'react';
import { IconButton, useToast } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import api from '../services/api';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../contexts/AuthContext';

const TestButton = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { accessToken } = useAuth();
    const toast = useToast();

    const handleTestClick = async () => {
        setIsLoading(true);
        
        try {
            if (accessToken) {
                const decodedToken = jwtDecode(accessToken);
                const expirationTime = decodedToken.exp * 1000;
                const remainingTime = expirationTime - Date.now();
                console.log('Remaining token lifetime (ms):', remainingTime);
            }

            const response = await api.post('/customer_management/testview', { message: 'test' });
            console.log('Response from testview:', response.data);
            
            toast({
                title: "Test Successful",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error sending test request:', error);
            toast({
                title: "Test Failed",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <IconButton
            icon={<CheckIcon />}
            onClick={handleTestClick}
            isLoading={isLoading}
            variant="unstyled"
            color="icon"
            aria-label="Test Connection"
            _hover={{
                color: 'icon-active',
                transform: 'scale(1.1)',
            }}
            transition="all 0.2s"
        />
    );
};

export default TestButton;