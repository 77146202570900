// NewChatButton.js
import React from 'react';
import { Button, Icon, Text, HStack } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useChat } from '../contexts/ChatContext';

export const NewChatButton = () => {
  const { sendMessage } = useWebSocket();
  const { loadChat } = useChat();

  const handleNewChat = () => {
    sendMessage({ type: 'new_chat', data: {} });
    loadChat(null);
  };

  return (
    <Button
      onClick={handleNewChat}
      variant="ghost"
      width="100%"
      justifyContent="flex-start"
      height="auto"
      py={2}
      px={4}
      _hover={{
        bg: 'button-primary-hover',
        color: 'button-text',
      }}
      transition="all 0.2s"
    >
      <HStack spacing={3}>
        <Icon as={AddIcon} boxSize={5} color="icon" />
        <Text>New Chat</Text>
      </HStack>
    </Button>
  );
};

export default NewChatButton;