import React, { createContext, useState, useContext, useEffect } from 'react';
import api, { setRefreshTokenFunction } from '../services/api';
import { CONFIG } from '../config';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  const decodeToken = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Failed to decode token:', error);
      return null;
    }
  };
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          const decodedToken = decodeToken(newAccessToken);
          if (decodedToken) {
            setUser(decodedToken.user_id);
          }
        }
      } catch (error) {
        console.error('Error during initializeAuth:', error);
      } finally {
        setLoading(false);
        setIsInitialized(true);
      }
    };
    initializeAuth();
      }, []);

  const login = async (credentials) => {
    if (!credentials.code) {
      console.error('No authorization code provided');
      return;
    }
    try {
      const response = await api.post(CONFIG.LOGIN_URL, {code:credentials});
      const { access_token, user } = response.data;
      console.log('response:', response.data); 
      setUser(user);
      setAccessToken(access_token);
      console.log('Access Token:', access_token);
      localStorage.setItem('accessToken', access_token);
      api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      console.log('Login successful:', user);
      // Note: The refresh token is now handled by the server as an HttpOnly cookie
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      const response = await api.post(CONFIG.LOGOUT_URL);
      console.log('Logout successful:', response.data);
      // The server will handle deleting the HttpOnly refresh token cookie
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setUser(null);
      setAccessToken(null);
      localStorage.removeItem('accessToken');
      delete api.defaults.headers.common['Authorization'];
      // console.log('Logout successful:', response.data);
    }
  };
  const refreshAccessToken = async () => {
    try {
      const response = await api.post(CONFIG.TOKEN_URL);
      const { access } = response.data;
      setAccessToken(access);
      localStorage.setItem('accessToken', access);
      api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
      return access;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      logout();
      throw error;
    }
  };

  useEffect(() => {
    setRefreshTokenFunction(refreshAccessToken);
  }, []);

  return (
    <AuthContext.Provider value={{ 
      accessToken, 
      user, 
      login, 
      logout, 
      refreshAccessToken, 
      loading,
      isInitialized 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

let refreshTokenFunction = null;

export const getRefreshTokenFunction = () => {
  if (!refreshTokenFunction) {
    throw new Error('Refresh token function not set');
  }
  return refreshTokenFunction;
};