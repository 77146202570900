// hooks/useDocumentActions.js
import { useToast } from '@chakra-ui/react';
import api from '../../../services/api';
import { CONFIG, getApiUrl } from '../../../config';

export const useDocumentActions = (documents, setDocuments) => {
  const toast = useToast();

  const handleDelete = async (docId) => {
    try {
      await api.delete(`${getApiUrl(CONFIG.PDF_DOCUMENTS_ENDPOINT)}/${docId}`);
      setDocuments(documents.filter(doc => doc.id !== docId));
      toast({
        title: 'Document deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error deleting document',
        description: error.response?.data?.error || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return { handleDelete };
};