import { useColorModeValue } from '@chakra-ui/react';

export const useAppColors = () => {
  return {
    // Background colors
    pageBg: useColorModeValue('gray.50', 'gray.900'),
    componentBg: useColorModeValue('white', 'gray.800'),
    
    // Text colors
    primaryText: useColorModeValue('gray.800', 'gray.200'),
    secondaryText: useColorModeValue('gray.600', 'gray.400'),
    
    // Border colors
    border: useColorModeValue('gray.200', 'gray.600'),
    
    // Button colors
    primaryButton: useColorModeValue('purple.800', 'purple.700'),
    primaryButtonHover: useColorModeValue('purple.900', 'purple.800'),
    
    // Message colors
    userMessageBg: useColorModeValue('purple.100', 'purple.800'),
    botMessageBg: useColorModeValue('gray.100', 'gray.700'),
    
    // Scrollbar color
    scrollbarThumb: useColorModeValue('gray.300', 'gray.600'),

    // Input colors
    inputBg: useColorModeValue('white', 'gray.700'),
    inputBorder: useColorModeValue('gray.300', 'gray.600'),
    inputFocus: useColorModeValue('purple.500', 'purple.400'),

    // Icon colors
    icon: useColorModeValue('gray.600', 'gray.400'),
    activeIcon: useColorModeValue('purple.800', 'purple.700'),
  };
}