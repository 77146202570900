// components/Sidebar/Sidebar.js
import React, { useEffect } from 'react';
import { Box, VStack, HStack, useColorModeValue } from '@chakra-ui/react';
import TabButton from './TabButton';
import ChatHistoryList from '../ChatHistoryList';
import CorporateLogo from './CorporateLogo';
import TUHHLogo from './IFPT';
import LoginButton from '../LoginWindow/LoginButton';
import SettingsButton from '../ModalComponent/SettingsButton';
import { useChatHistory } from '../../hooks/useChatHistory';
import { NewChatButton } from '../NewChatButton';

const Sidebar = ({ activeTab, onTabChange, onSelectChat }) => {
  const componentBg = useColorModeValue('bg-component', 'bg-component');
  const borderColor = useColorModeValue('border-color', 'border-color');
  const tabs = ['Research', 'Write', 'Review', 'Chat'];
  const { fetchChatHistories } = useChatHistory();
  
  useEffect(() => {
    if (activeTab === 'Chat') {
      fetchChatHistories();
    }
  }, [activeTab]);

  return (
    <Box
      width="200px"
      height="100vh"
      display="flex"
      flexDirection="column"
      bg={componentBg}
      borderRight="1px"
      borderColor={borderColor}
    >
      {/* Top section with logo and navigation */}
      <Box flex="1" overflow="auto">
        <CorporateLogo />
        {/* <TUHHLogo /> */}
        <VStack 
          spacing={1} 
          align="stretch" 
          p={2}
          width="100%"
        >
          {tabs.map((tab) => (
            <Box 
              key={tab}
              width="100%"
              px={2}
              py={1}
              _hover={{
                cursor: 'pointer',
              }}
            >
              <TabButton
                tab={tab}
                isActive={activeTab === tab}
                onClick={() => onTabChange(tab)}
              />
            </Box>
          ))}
          {activeTab === 'Chat' && (
            <Box 
              width="100%"
              px={2}
              py={1}
            >
              <NewChatButton />
            </Box>
          )}
        </VStack>
        {activeTab === 'Chat' && (
          <Box mt={4} px={4}>
            <ChatHistoryList onSelectChat={onSelectChat} />
          </Box>
        )}
      </Box>

      {/* Bottom section with login button */}
      <Box 
        p={4} 
        borderTop="none"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={componentBg}
      > 
        <Box display="flex" gap={2}>
          <LoginButton />
          <SettingsButton />
        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;