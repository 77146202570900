// CopyIcon.js
import React from 'react';
import { IconButton, useToast } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

export const CopyIconButton = ({ content, colors, ...props }) => {
  const toast = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(content).then(() => {
      toast({
        title: "Code copied to clipboard",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
      toast({
        title: "Failed to copy code",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  return (
    <IconButton
      icon={<CopyIcon />}
      size="xs"
      aria-label="Copy code"
      onClick={handleCopy}
      position="absolute"
      top={1}
      right={1}
      bg="transparent"
      color={colors.primaryText}
      _hover={{ bg: colors.componentBg }}
      {...props}
    />
  );
};