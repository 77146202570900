// components/Layout/AppLayout.js
import React from 'react';
import { Flex, Box, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar/Sidebar';
import MainContent from '../MainContent/MainContent';

const AppLayout = ({ activeTab, selectedChatId, onTabChange, onSelectChat }) => {
  const bgColor = useColorModeValue('bg-page', 'bg-page');

  return (
    <Flex height="100vh" bg={bgColor}>
      {/* Left Sidebar */}
      <Sidebar
        activeTab={activeTab}
        onTabChange={onTabChange}
        onSelectChat={onSelectChat}
      />
      
      {/* Right side container */}
      <Flex flex={1} flexDirection="column">
        <Navbar />
        <Box flex={1} position="relative" overflow="hidden">
          <MainContent 
            activeTab={activeTab}
            selectedChatId={selectedChatId}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default AppLayout;