// MarkdownContent.js
import React from 'react';
import { Box } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyIconButton } from './CopyIcon';

export const MarkdownContent = ({ content, colors }) => {
  const MarkdownComponents = {
    p: ({ node, ...props }) => <Box as="p" mb={2} color={colors.primaryText} {...props} />,
    h1: ({ node, ...props }) => <Box as="h1" fontSize="xl" fontWeight="bold" mb={2} color={colors.primaryText} {...props} />,
    h2: ({ node, ...props }) => <Box as="h2" fontSize="lg" fontWeight="bold" mb={2} color={colors.primaryText} {...props} />,
    h3: ({ node, ...props }) => <Box as="h3" fontSize="md" fontWeight="bold" mb={2} color={colors.primaryText} {...props} />,
    ul: ({ node, ...props }) => (
      <Box as="ul" pl={4} mb={2} color={colors.primaryText} listStylePosition="inside" {...props} />
    ),
    ol: ({ node, ...props }) => (
      <Box as="ol" pl={4} mb={2} color={colors.primaryText} listStylePosition="inside" {...props} />
    ),
    li: ({ node, ...props }) => (
      <Box as="li" mb={1} color={colors.primaryText} pl={1} {...props} />
    ),
    code: ({ node, inline, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <Box mb={3} position="relative">
          <SyntaxHighlighter
            style={tomorrow}
            language={match[1]}
            PreTag="div"
            customStyle={{
              margin: '0.5em 0',
              borderRadius: '0.3em',
              padding: '0.5em',
              paddingRight: '2em',
              backgroundColor: colors.componentBg,
            }}
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
          <CopyIconButton 
            content={String(children)} 
            colors={colors}
            size="xs"
            top={2}
            right={2}
          />
        </Box>
      ) : (
        <Box
          as="code"
          display="inline-block"
          p={1}
          bg={colors.componentBg}
          color={colors.primaryText}
          borderRadius="md"
          fontFamily="monospace"
          fontSize="sm"
          whiteSpace="pre-wrap"
          wordBreak="break-word"
          {...props}
        >
          {children}
        </Box>
      );
    },
    a: ({ node, ...props }) => (
      <Box
        as="a"
        color={colors.primaryButton}
        textDecoration="underline"
        _hover={{ color: colors.primaryButtonHover }}
        {...props}
      />
    ),
    blockquote: ({ node, ...props }) => (
      <Box
        as="blockquote"
        borderLeftWidth="2px"
        borderLeftColor={colors.border}
        pl={2}
        py={1}
        mb={2}
        color={colors.secondaryText}
        fontStyle="italic"
        {...props}
      />
    ),
  };

  return (
    <Box overflowX="auto" width="100%">
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]}
        components={MarkdownComponents}
      >
        {content}
      </ReactMarkdown>
    </Box>
  );
}