// components/DocumentManager/components/DocumentModal.js
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    VStack,
    HStack,
    Text,
    Badge,
  } from '@chakra-ui/react';
  import { formatDateTime } from '../utils/formatters';
  import { getStatusColor } from '../utils/statusHelpers';
  
  export const DocumentModal = ({ isOpen, onClose, document }) => {
    if (!document) return null;
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{document.file_name}</ModalHeader>
          <ModalBody>
            <VStack align="stretch" spacing={3}>
              <HStack justify="space-between">
                <Text fontWeight="bold">Status:</Text>
                <Badge colorScheme={getStatusColor(document.status)}>
                  {document.status}
                </Badge>
              </HStack>
              <HStack justify="space-between">
                <Text fontWeight="bold">Created:</Text>
                <Text>
                  {formatDateTime(document.created_at)}
                </Text>
              </HStack>
              <HStack justify="space-between">
                <Text fontWeight="bold">Pages:</Text>
                <Text>{document.page_count}</Text>
              </HStack>
              <HStack justify="space-between">
                <Text fontWeight="bold">Size:</Text>
                <Text>
                  {document.file_size 
                    ? `${(document.file_size / 1024 / 1024).toFixed(2)} MB` 
                    : 'N/A'}
                </Text>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  
  export default DocumentModal;