import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ColorModeScript } from '@chakra-ui/react'
//import theme from './theme'
import theme from './themes/theme';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <Fragment>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
  </Fragment>
  //</React.StrictMode>
);

reportWebVitals();