// components/LoginButton.js
import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import CustomModal from '../ModalComponent/CustomModal';
import GoogleLoginButton from '../GoogleLoginButton';
import { useAuth } from '../../contexts/AuthContext';
import LogoutButton from '../CorporateButtons/LogoutButton';

const LoginButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, logout } = useAuth();

  const handleLogout = () => {
    console.log("Logout initiated"); // Add this log
    logout();
    console.log("Logout completed"); // Add this log
  };

  return user ? (
    <LogoutButton onClick={handleLogout} />
  ) : (
    <>
      <Button
        leftIcon={<FaUser />}
        onClick={onOpen}
        variant="secondary"
      >
        Login
      </Button>
      <CustomModal isOpen={isOpen} onClose={onClose} title="Login">
        <GoogleLoginButton />
      </CustomModal>
    </>
  );
};

export default LoginButton;
// // components/LoginButton.js
// import React from 'react';
// import { Button, useDisclosure } from '@chakra-ui/react';
// import { FaUser } from 'react-icons/fa';
// import CustomModal from '../ModalComponent/CustomModal';
// import GoogleLoginButton from '../GoogleLoginButton';
// import { useAuth } from '../../contexts/AuthContext';
// import LogoutButton from '../CorporateButtons/LogoutButton';

// const LoginButton = () => {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const { user, login, logout } = useAuth();

//   return user ? (
//     <LogoutButton onClick={logout} />
//   ) : (
//     <>
//       <Button
//         leftIcon={<FaUser />}
//         onClick={onOpen}
//         variant="secondary"
//       >
//         Login
//       </Button>
//       <CustomModal isOpen={isOpen} onClose={onClose} title="Login">
//         <GoogleLoginButton />
//       </CustomModal>
//     </>
//   );

// };

// export default LoginButton;