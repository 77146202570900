// components/Settings.js
import React from 'react';
import { VStack, Heading, Switch, FormControl, FormLabel, Icon, useColorMode } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { useAppColors } from '../../colors/colors';

const Settings = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const colors = useAppColors();

  return (
    <VStack spacing={4} align="stretch">
      <Heading size="md" color="text-primary">Settings</Heading>
      <FormControl display="flex" alignItems="center">
      {/* <Icon as={SunIcon} mr={2} color={colorMode === 'light' ? colors.activeIcon : colors.icon} />
        <Switch
          isChecked={colorMode === 'dark'}
          onChange={toggleColorMode}
          colorScheme="teal"
        />
        <Icon as={MoonIcon} ml={2} color={colorMode === 'dark' ? colors.activeIcon : colors.icon} /> */}
        <FormLabel htmlFor="dark-mode" mb="0" color="text-secondary">
          Dark Mode
        </FormLabel>
        <Switch isChecked={colorMode === 'dark'}
          onChange={toggleColorMode}
          colorScheme="teal"
          />
      </FormControl>
      {/* <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="notifications" mb="0" color="text-secondary">
          Notifications
        </FormLabel>
        <Switch id="notifications" colorScheme="brand" />
      </FormControl> */}
      
      {/* Add more settings options here */}
    </VStack>
  );
};

export default Settings;