// DocumentManager.js
import React, { useEffect, useState, useCallback } from 'react';
import { Box, useDisclosure, Heading, VStack, HStack, IconButton } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
//import LoadingState

import { DocumentHeader } from './components/DocumentHeader';
import { DocumentGrid } from './components/DocumentGrid';
import { DocumentModal } from './components/DocumentModal';
import { EmptyState } from './components/EmptyState';
import { LoadingState } from './components/LoadingState';
import { useDocuments } from './hooks/useDocuments';
import { useDocumentActions } from './hooks/useDocumentActions';
import { FilterPanel } from './components/FilterPanel';
import FileUploadComponent from '../FileUploadComponent'; // Ensure this import is correct


function DocumentManager() {
  const { documents, setDocuments, isLoading, isRefreshing, fetchDocuments } = useDocuments();
  const { handleDelete } = useDocumentActions(documents, setDocuments);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [sortBy, setSortBy] = useState('date_desc');

  const getFilteredAndSortedDocuments = useCallback(() => {
    let filtered = [...documents];

      // Apply status filter
      if (selectedStatuses.length > 0) {
        filtered = filtered.filter(doc => selectedStatuses.includes(doc.status));
      }
  
      // Apply sorting
      filtered.sort((a, b) => {
        switch (sortBy) {
          case 'name_asc':
            return a.file_name.localeCompare(b.file_name);
          case 'name_desc':
            return b.file_name.localeCompare(a.file_name);
          case 'date_asc':
            return new Date(a.created_at) - new Date(b.created_at);
          case 'date_desc':
            return new Date(b.created_at) - new Date(a.created_at);
          case 'pages_asc':
            return a.page_count - b.page_count;
          case 'pages_desc':
            return b.page_count - a.page_count;
          default:
            return 0;
        }
      });
  
      return filtered;
    }, [documents, selectedStatuses, sortBy]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleView = (doc) => {
    setSelectedDoc(doc);
    onOpen();
  };
  return (
    <Box h="100%" display="flex" flexDirection="column">
      <Box p={5} borderBottom="1px" borderColor="gray.200">
        <Heading size="lg" mb={4}>Document Management</Heading>
        <FileUploadComponent onUploadComplete={fetchDocuments} />
      </Box>

      <Box flex="1" display="flex" overflow="hidden">
        <FilterPanel
          selectedStatuses={selectedStatuses}
          onStatusChange={setSelectedStatuses}
          sortBy={sortBy}
          onSortChange={setSortBy}
        />

        <Box
          flex="1"
          overflowY="auto"
          p={5}
          css={{
            '&::-webkit-scrollbar': { width: '8px' },
            '&::-webkit-scrollbar-track': {
              width: '10px',
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'gray.300',
              borderRadius: '24px',
            },
          }}
        >
          <VStack spacing={6} align="stretch">
            <Box>
              <HStack justify="space-between" mb={4}>
                <Heading size="md">Your Documents</Heading>
                <IconButton
                  icon={<RepeatIcon />}
                  isLoading={isRefreshing}
                  onClick={fetchDocuments}
                  size="sm"
                  aria-label="Refresh documents"
                />
              </HStack>

              {isLoading ? (
                <LoadingState />
              ) : documents.length === 0 ? (
                <EmptyState />
              ) : (
                <DocumentGrid
                  documents={getFilteredAndSortedDocuments()}
                  onView={handleView}
                  onDelete={handleDelete}
                />
              )}
            </Box>
          </VStack>
        </Box>
      </Box>

      <DocumentModal
        isOpen={isOpen}
        onClose={onClose}
        document={selectedDoc}
      />
    </Box>
  );
  // return (
  //   <Box h="100%" display="flex" flexDirection="column">
  //     <DocumentHeader onUploadComplete={fetchDocuments} />
  //     <Box
  //       flex="1"
  //       overflowY="auto"
  //       p={5}
  //       css={{
  //         '&::-webkit-scrollbar': { width: '8px' },
  //         '&::-webkit-scrollbar-track': {
  //           width: '10px',
  //           background: 'transparent',
  //         },
  //         '&::-webkit-scrollbar-thumb': {
  //           background: 'gray.300',
  //           borderRadius: '24px',
  //         },
  //       }}
  //     >
  //       {isLoading ? (
  //         <LoadingState />
  //       ) : documents.length === 0 ? (
  //         <EmptyState />
  //       ) : (
  //         <DocumentGrid
  //           documents={documents}
  //           onView={handleView}
  //           onDelete={handleDelete}
  //         />
  //       )}
  //     </Box>
  //     <DocumentModal
  //       isOpen={isOpen}
  //       onClose={onClose}
  //       document={selectedDoc}
  //     />
  //   </Box>
  // );
}

export default DocumentManager;