// components/BurgerMenu.js
import React from 'react';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    useColorModeValue,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

const BurgerMenu = () => {
    const menuBg = useColorModeValue('bg-component', 'bg-component');
    const borderColor = useColorModeValue('border-color', 'border-color');

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon color="icon" boxSize={6} />}
                variant="unstyled"
            />
            <MenuList
                bg={menuBg}
                borderColor={borderColor}
                boxShadow="lg"
                mt={2}
            >
                <MenuItem 
                    _hover={{ color: 'button-text', bg: 'button-primary-hover' }}
                    transition="all 0.2s"
                >
                    Settings
                </MenuItem>
                <MenuItem 
                    _hover={{ color: 'button-text', bg: 'button-primary-hover' }}
                    transition="all 0.2s"
                >
                    Profile
                </MenuItem>
                <MenuItem 
                    _hover={{ color: 'button-text', bg: 'button-primary-hover' }}
                    transition="all 0.2s"
                >
                    Help Center
                </MenuItem>
                <MenuItem 
                    _hover={{ color: 'button-text', bg: 'button-primary-hover' }}
                    transition="all 0.2s"
                >
                    About Us
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default BurgerMenu;