import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleSignInButton from './CorporateButtons/GoogleSignInButton';
import LogoutButton from './CorporateButtons/LogoutButton';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path as needed

function GoogleLoginButton() {
  const { user, login, logout } = useAuth();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      try {
        // Use the login function from AuthContext
        await login({ code: credentialResponse.code });
        console.log('Google login successful');
      } catch (error) {
        console.error('Login failed:', error);
      }
    },
    onError: (error) => console.error('Google Login Error:', error),
    flow: 'auth-code',
  });

  return user ? (
    <LogoutButton onClick={logout} />
  ) : (
    <GoogleSignInButton onClick={() => handleGoogleLogin()} />
  );
}

// import React from 'react';
// import { useGoogleLogin } from '@react-oauth/google';
// import GoogleSignInButton from './CorporateButtons/GoogleSignInButton';
// import LogoutButton from './CorporateButtons/LogoutButton';
// import { useAuth } from '../contexts/AuthContext'; // Adjust the path as needed
// import api from '../services/api'; // Adjust the path as needed

// function GoogleLoginButton() {
//   const { user, login, logout } = useAuth();

//   const handleGoogleLogin = useGoogleLogin({
//     onSuccess: async (credentialResponse) => {
//       try {
//         const response = await api.post('/customer_management/google_login', {
//           code: credentialResponse.code
//         });
//         login(response.data.user, response.data.tokens);
//       } catch (error) {
//         console.error('Login failed:', error);
//       }
//     },
//     flow: 'auth-code',
//   });

//   return user ? (
//     <LogoutButton onClick={logout} />
//   ) : (
//     <GoogleSignInButton onClick={handleGoogleLogin} />
//   );
// }

export default GoogleLoginButton;