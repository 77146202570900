import React from 'react';
import { Box, Flex} from '@chakra-ui/react';
import { useAppColors } from '../colors/colors';

import TestButton from './TestButton';

import BurgerMenu from './BurgerMenu';
import { ReconnectAlert } from './ReconnectAlert';
import VectorSearchButton, {vectorSearchButton} from '../components/VectorSearch/VectorSearchButton';
import UUIDSearchButton from './VectorSearch/UUIDSearchButton';

function Navbar() {
  const colors = useAppColors();

  return (
    <Box py={0} px={8} bg={colors.componentBg} borderBottom="1px" borderColor={colors.border}>
      {/* <Box>
      <UUIDSearchButton />
      </Box>
    <Box>
      <VectorSearchButton searchText={"synthehtic data"}/>
    </Box> */}
      <Box>
      <ReconnectAlert />
      {/* rest of layout */}
    </Box>
      <Flex justifyContent="flex-end" alignItems="center">
      <Box pr={4}>
      <TestButton />
      </Box>
      <Box pr={1}>
      <BurgerMenu />
      </Box>
      </Flex>
      
    </Box>
  );
}

export default Navbar;