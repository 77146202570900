// components/DocumentManager/components/FilterPanel.js
import React from 'react';
import {
  Box,
  VStack,
  Heading,
  Checkbox,
  CheckboxGroup,
  Select,
  Divider,
} from '@chakra-ui/react';

export const FilterPanel = ({ 
  selectedStatuses, 
  onStatusChange,
  sortBy,
  onSortChange
}) => {
  const statusOptions = [
    { value: 'completed', label: 'Completed' },
    { value: 'processing', label: 'Processing' },
    { value: 'analyzing', label: 'Analyzing' },
    { value: 'failed', label: 'Failed' },
    { value: 'uploading', label: 'Uploading' }
  ];

  const sortOptions = [
    { value: 'name_asc', label: 'Name (A-Z)' },
    { value: 'name_desc', label: 'Name (Z-A)' },
    { value: 'date_asc', label: 'Date (Oldest)' },
    { value: 'date_desc', label: 'Date (Newest)' },
    { value: 'pages_asc', label: 'Pages (Low to High)' },
    { value: 'pages_desc', label: 'Pages (High to Low)' }
  ];

  return (
    <Box 
      w="200px" 
      p={4} 
      borderRight="1px" 
      borderColor="gray.200"
      h="100%"
    >
      <VStack align="stretch" spacing={6}>
        <Box>
          <Heading size="sm" mb={3}>Sort By</Heading>
          <Select 
            value={sortBy} 
            onChange={(e) => onSortChange(e.target.value)}
          >
            {sortOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Box>

        <Divider />

        <Box>
          <Heading size="sm" mb={3}>Status Filter</Heading>
          <CheckboxGroup
            value={selectedStatuses}
            onChange={onStatusChange}
          >
            <VStack align="start" spacing={2}>
              {statusOptions.map(status => (
                <Checkbox key={status.value} value={status.value}>
                  {status.label}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Box>
      </VStack>
    </Box>
  );
};

export default FilterPanel;