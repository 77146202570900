// components/SendButton.js
import React from 'react';
import { IconButton, useColorModeValue, useTheme } from '@chakra-ui/react';
import { ReactComponent as SendIconSvg } from '../../icons/chat_icon.svg';

const SendIcon = ({ size, ...props }) => (
  <SendIconSvg width={size} height={size} {...props} />
);

export const SendButton = ({ sendMessage, iconSize, buttonWidth }) => {
  const theme = useTheme();
  const buttonBg = useColorModeValue('button-primary', 'button-primary');
  const buttonHoverBg = useColorModeValue('button-primary-hover', 'button-primary-hover');
  const buttonColor = useColorModeValue('button-text', 'button-text');

  return (
    <IconButton
      icon={<SendIcon size={iconSize} />}
      onClick={sendMessage}
      aria-label="Send message"
      bg={buttonBg}
      color={buttonColor}
      _hover={{ 
        bg: buttonHoverBg,
      }}
      _active={{ 
        bg: buttonHoverBg,
      }}
      borderRadius="full"
      width={buttonWidth}
      height={`calc(${iconSize} + 4px)`}
      minWidth={`calc(${iconSize} + 16px)`}
      padding="0"
      transition="all 0.2s ease-in-out"
    />
  );
}