// components/ChatInputArea/InputArea.js
import React, { useState } from 'react';
import { Box, useToken, Flex } from '@chakra-ui/react';
import { SendButton } from './SendButton';
import { PlaceholderText } from './PlaceholderText';
import { ChatTextarea } from './ChatTextArea';
import '../../App.css';
import {UploadButton} from '../DocumentManager/components/UploadButton';


export const InputArea = ({ inputMessage, setInputMessage, sendMessage, onFocusChange }) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  
  const handleFocus = () => {
    onFocusChange?.(true); // Call the parent's focus handler
  };

  const handleBlur = () => {
    onFocusChange?.(false); // Call the parent's blur handler
  };

  const [border, inputBg, primaryText, inputBorder, inputFocus] = useToken(
    'colors',
    ['border-color', 'input-bg', 'text-primary', 'input-border', 'input-focus']
  );
  const colors = {
    border,
    inputBg,
    primaryText,
    inputBorder,
    inputFocus
  };
  
  const iconSize = "40px";
  const buttonWidth = `calc(${iconSize} + 2.5rem)`; // Reduced padding
  const buttonWidthAddPDF = `calc(${iconSize})`; // Reduced padding
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };
  return (
    <Box 
      p={4} 
      bg={colors.componentBg}
      borderColor={colors.border}
      position="relative"
      borderTop="none"
      backdropFilter="blur(10px)"
    >
      <Flex position="relative" alignItems="center">
        {/* Upload Button */}
        <Box 
          position="absolute" 
          left={2} 
          bottom={3.5} 
          zIndex={4}
        >
          <UploadButton 
            size="sm"
            onUploadComplete={(result) => {
              console.log('Upload completed:', result);
            }}
          />
        </Box>

        {/* Chat Input Area */}
        <Box 
          flex={1}
          
          position="relative"
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <ChatTextarea 
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleKeyPress={handleKeyPress}
            colors={colors}
            pl={`calc(${buttonWidthAddPDF} + 0.7rem)`}
            pr={`calc(${buttonWidth} + 1.25rem)`}
          />
          {!inputMessage && <PlaceholderText colors={colors} />}
        </Box>

        {/* Send Button */}
        <Box 
          position="absolute" 
          right={4} 
          bottom={2} 
          zIndex={4}
        >
          <SendButton 
            sendMessage={sendMessage} 
            iconSize={iconSize} 
            buttonWidth={buttonWidth} 
          />
        </Box>
      </Flex>
    </Box>
  );
}