// components/DocumentManager/components/UploadButton.js
import React from 'react';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import FileUploadComponent from '../../FileUploadComponent';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';

export const UploadButton = ({ onUploadComplete, size = "sm", ariaLabel = "Upload PDF" }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUploadComplete = (result) => {
    // Close the modal
    onClose();
    
    // Call the parent's completion handler
    onUploadComplete?.(result);
  };
  // const handleUploadComplete = (...args) => {
  //   onUploadComplete?.(...args);
  //   onClose();
  // };


  return (
    <>
      <IconButton
        icon={<AddIcon boxSize={5} thickness="3px" />}
        isRound
        colorScheme="blue"
        size={size}
        onClick={onOpen}
        aria-label={ariaLabel}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload PDF</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FileUploadComponent onUploadComplete={handleUploadComplete} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadButton;