// FileUploadComponent.js
import React, { useState, useEffect } from 'react';
import { Box, Text, Progress, Button } from '@chakra-ui/react';
import { useWebSocket } from '../contexts/WebSocketContext';
import api from '../services/api';
import { CONFIG, getApiUrl } from '../config';

function FileUploadComponent({ onUploadComplete }) {
  const { socket } = useWebSocket();
  const [uploadStatus, setUploadStatus] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  const resetState = () => {
    setUploadStatus(null);
    setProgress(0);
    setIsUploading(false);
    setError(null);
  };

  const handleFileUpload = async (file) => {
    try {
      resetState();
      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', file);

      // Use your configured api instance
      const response = await api.post(getApiUrl(CONFIG.PDF_UPLOAD_ENDPOINT), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // Authorization header will be automatically added by your interceptor
        },
        onUploadProgress: (progressEvent) => {
          // Add upload progress tracking
          const uploadPercentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(uploadPercentage);
          setUploadStatus(`Uploading: ${uploadPercentage}%`);
        },
      });

      console.log('Upload response:', response.data);
      setUploadStatus('Upload completed, processing file...');

    } catch (error) {
      console.error('Upload error:', error);
      setError(
        error.response?.data?.error || 
        error.message || 
        'An error occurred during upload'
      );
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        
        if (data.type === 'processing_status') {
          const statusData = data.data;
          console.log('Received status update:', statusData);

          setUploadStatus(statusData.message);
          if (statusData.progress !== undefined) {
            setProgress(statusData.progress);
          }

          if (statusData.status === 'completed') {
            setIsUploading(false);
            // Maybe store or handle the document ID
            if (statusData.document_id) {
              console.log('Document ID:', statusData.document_id);
            }
          } else if (statusData.status === 'error') {
            setError(statusData.message);
            setIsUploading(false);
          }
        }
      };

      socket.addEventListener('message', handleMessage);
      return () => socket.removeEventListener('message', handleMessage);
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        
        if (data.type === 'processing_status') {
          const statusData = data.data;
          console.log('Received status update:', statusData);

          setUploadStatus(statusData.message);
          if (statusData.progress !== undefined) {
            setProgress(statusData.progress);
          }

          if (statusData.status === 'completed') {
            setIsUploading(false);
            // Call onUploadComplete when processing is done
            if (onUploadComplete) {
              onUploadComplete();
            }
          } else if (statusData.status === 'error') {
            setError(statusData.message);
            setIsUploading(false);
          }
        }
      };

      socket.addEventListener('message', handleMessage);
      return () => socket.removeEventListener('message', handleMessage);
    }
  }, [socket, onUploadComplete]); // Add onUploadComplete to dependencies
  return (
    <Box>
      <input 
        type="file" 
        accept=".pdf"
        onChange={(e) => {
          if (e.target.files[0]) {
            handleFileUpload(e.target.files[0]);
          }
        }}
        disabled={isUploading}
      />
      
      {(uploadStatus || isUploading) && (
        <Box mt={4}>
          <Text mb={2}>{uploadStatus}</Text>
          <Progress 
            value={progress} 
            size="sm" 
            colorScheme="blue" 
            borderRadius="md"
            isIndeterminate={isUploading && progress === 0}
          />
          {progress > 0 && (
            <Text fontSize="sm" mt={1}>
              Progress: {progress}%
            </Text>
          )}
        </Box>
      )}

      {error && (
        <Box mt={4} color="red.500">
          <Text>{error}</Text>
          <Button 
            mt={2} 
            size="sm" 
            onClick={resetState}
          >
            Try Again
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default FileUploadComponent;