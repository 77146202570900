
// components/Sidebar/TabButton.js
import React from 'react';
import { Box, Text, HStack, Icon, useColorModeValue } from '@chakra-ui/react';
import { 
  FaSearch, // for Research
  FaPencilAlt, // for Write
  FaClipboardCheck, // for Review
  FaComments // for Chat
} from 'react-icons/fa';

const getIconForTab = (tab) => {
  switch (tab) {
    case 'Research':
      return FaSearch;
    case 'Write':
      return FaPencilAlt;
    case 'Review':
      return FaClipboardCheck;
    case 'Chat':
      return FaComments;
    default:
      return FaSearch;
  }
};

const TabButton = ({ tab, isActive, onClick }) => {
  const textColor = useColorModeValue('text-primary', 'text-primary');
  const IconComponent = getIconForTab(tab);

  return (
    <Box
      as="button"
      bg={isActive ? 'button-tertiary' : 'transparent'}
      color={isActive ? 'button-text' : textColor}
      p={2}
      borderRadius="md"
      fontWeight={isActive ? 'bold' : 'normal'}
      onClick={onClick}
      width="100%"
      _hover={{
        bg: 'button-primary-hover',
        color: 'button-text',
      }}
      transition="all 0.2s"
    >
      <HStack spacing={3} justify="flex-start">
        <Icon as={IconComponent} boxSize={5} />
        <Text>{tab}</Text>
      </HStack>
    </Box>
  );
};

export default TabButton;