// components/EmptyState.js
import { Center, VStack, Text } from '@chakra-ui/react';

export const EmptyState = () => (
  <Center p={8}>
    <VStack spacing={3}>
      <Text>No documents found</Text>
      <Text color="gray.500" fontSize="sm">
        Upload a PDF to get started
      </Text>
    </VStack>
  </Center>
);